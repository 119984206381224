<template>
  <div class="widgetContainer full-width">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">
          Vendors
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  }

};
</script>
<style lang="scss" scoped>
.statementDetail {
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }

  &__blank {
    p {
      color: var(--grey-1);
      text-align: center;
      padding-top: 20px;
    }
  }

  &__actions {
    .main-title {
      text-transform: uppercase;
      color: rgba(#3c3c43, 0.6);
      font-size: 12px;
      line-height: 16px;
      padding: 8px 0 8px 0;
    }

    li {
      // border-radius: var(--radius);
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid var(--light-grey-3);

      &:last-of-type {
        border-bottom: none;
      }

      .info {
        margin: 0 auto 0 0px;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .description {
          font-size: 12px;
          line-height: 16px;
          color: var(--grey-1);
        }
      }

      .action {
        cursor: pointer;

        .select {
          color: var(--branding);
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          text-decoration: underline;
          text-transform: uppercase;
        }

        .icon-tick {
          color: var(--branding);
          font-size: 14px;
          display: none;
        }
      }

      .icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        background-color: var(--branding);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        span {
          color: #fff;
        }
      }

      .icon-arrow-right {
        margin-left: auto;
        color: #c7c7c9;
        font-size: 13px;
      }
    }
  }
}
</style>
